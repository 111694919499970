import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";

import scholarImg from "../../assets/Images/sda_scholarship.png";
import flyerImg from "../../assets/Images/sda_Scholarship_Flyer_2025.png";
import legacyImg from "../../assets/Images/missionStatement.png";

const ScholarshipPage: React.FC = () => {
  return (
    <div className="">
      <Navbar />
      {/* Hero Section */}
      <div className="relative w-full h-32 md:h-48 lg:h-64">
        <img
          src={scholarImg}
          alt="Scholarship banner"
          className="w-full h-full object-cover rounded-b-lg"
        />
      </div>

      {/* Main Content */}
      <div className="px-4 md:px-8 lg:px-12 py-8 md:py-12 space-y-16 max-w-7xl mx-auto">
        {/* About Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <img
            src={legacyImg}
            alt="Legacy"
            className="w-full rounded-lg shadow-md"
          />
          <div className="space-y-6">
            <h1 className="text-3xl md:text-4xl font-bold font-oswald text-gray-800">
              About Sigma Delta Alpha
            </h1>
            <p className="text-gray-700 font-merriweather leading-relaxed">
              In the Fall semester of 1991, Francisco Huerta, a student at San
              Jose State University, envisioned creating an organization to
              serve the needs of Latinos on campus. He led the initial meetings
              that laid the foundation for Sigma Delta Alpha. By November 1991,
              the first introductory meeting took place, setting the
              fraternity's ideological purposes. After several meetings and
              successful events, the fraternity was officially recognized on May
              5, 1992, with 18 Founding Fathers. Sigma Delta Alpha is notable
              for being one of only two Latino fraternities founded during the
              fragmentation period from 1990 to 2000 and the third in
              California.
            </p>
            <div className="mt-4 items-center text-center">
              <a
                href="/our-story"
                className="inline-block bg-black font-bold text-white py-2 px-6 rounded-lg shadow-md hover:bg-gray-800 transition"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>

        {/* Scholarship Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div className="space-y-6">
            <h1 className="text-3xl md:text-4xl font-bold font-oswald text-gray-800">
              Our Sigma Scholarship
            </h1>
            <p className="text-gray-700 font-merriweather leading-relaxed">
              Sigma Delta Alpha Fraternity is proud to introduce a scholarship
              dedicated to empowering the next generation of Latino leaders. Our
              purpose is to support and encourage high school students of Latino
              heritage who are pursuing higher education and are committed to
              promoting and retaining Latinos in college. By providing this
              scholarship, we aim to invest in young scholars who are passionate
              about creating opportunities, fostering academic success, and
              making a lasting impact within their communities.
            </p>

            {/* Qualifications Section */}
            <div className="bg-white shadow-md rounded-lg p-6 space-y-4">
              <h2 className="text-xl font-bold text-gray-800">
                Qualifications:
              </h2>
              <ul className="list-disc list-inside text-gray-700 space-y-1 font-merriweather">
                <li>High School Senior</li>
                <li>2.7 GPA or Above</li>
                <li>
                  Identify as Latino and male, interested in the promotion and
                  retention of Latinos in higher education
                </li>
              </ul>
            </div>

            {/* Application CTA */}
            <div className="flex flex-col items-center text-center space-y-4">
              <h5 className="text-2xl md:text-3xl font-bold font-oswald text-gray-800">
                Interested In Applying?
              </h5>
              <p className="text-lg text-gray-700">
                Applications open on April 5th, 2025
              </p>
              <button
                type="button"
                className="bg-gradient-to-br from-[#800000] to-[#228B22] hover:bg-gradient-to-bl text-white font-medium text-lg rounded-lg px-6 py-3 shadow-md transition"
              >
                Starts April 5th
              </button>
            </div>
          </div>

          <img
            src={flyerImg}
            alt="Scholarship Flyer"
            className="w-full rounded-lg shadow-md"
          />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ScholarshipPage;

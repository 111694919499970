import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import apiUrl from "../constants/apiUrl";

interface Credentials {
  username: string;
  password: string;
}

const authUrl = `${apiUrl}`;

export const login = createAsyncThunk(
  "auth/login",
  async (credentials: Credentials, { rejectWithValue }) => {
    try {
      const response: AxiosResponse = await axios.post(
        `${authUrl}/token/`,
        credentials
      );
      const { access_token, refresh_token } = response.data;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const logout = createAsyncThunk<void>("auth/logout", async () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
});

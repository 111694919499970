import axios, { AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apiUrl from "../constants/apiUrl";
import { CAROUSEL_POST, CREATE_CAROUSEL_POST_FORM } from "../@types";

const baseApiUrl = `${apiUrl}/carousel-posts/`;

export const GET_CAROUSEL_POSTS = "GET_CAROUSEL_POSTS";
export const getCarouselPosts = createAsyncThunk(
  GET_CAROUSEL_POSTS,
  async () => {
    const res: AxiosResponse = await axios.get(baseApiUrl);
    return res.data;
  }
);

export const CREATE_CAROUSEL_POST = "CREATE_CAROUSEL_POST";
export const createCarouselPost = createAsyncThunk(
  CREATE_CAROUSEL_POST,
  async (carouselPostData: CREATE_CAROUSEL_POST_FORM, { rejectWithValue }) => {
    try {
      const res: AxiosResponse = await axios.post(baseApiUrl, carouselPostData);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const DELETE_CAROUSEL_POST = "DELETE_CAROUSEL_POST";
export const deleteCarouselPost = createAsyncThunk(
  DELETE_CAROUSEL_POST,
  async (CarouselPostId: number | string) => {
    try {
      await axios.delete(`${baseApiUrl}${CarouselPostId}/`, {});
      return CarouselPostId;
    } catch (err) {
      return false;
    }
  }
);

export const EDIT_CAROUSEL_POST = "EDIT_CAROUSEL_POST";
export const editCarouselPost = createAsyncThunk(
  EDIT_CAROUSEL_POST,
  async (carouselPostToEdit: CAROUSEL_POST) => {
    try {
      const { id, ...carouselPostDataToSend } = carouselPostToEdit;
      await axios.put(`${baseApiUrl}${id}/`, carouselPostDataToSend);
      return carouselPostToEdit;
    } catch (err) {
      return null;
    }
  }
);

export const GET_CAROUSEL_POST = "GET_CAROUSEL_POST";
export const getCarouselPost = createAsyncThunk(
  GET_CAROUSEL_POST,
  async (id: string | number) => {
    const res: AxiosResponse = await axios.get(`${baseApiUrl}${id}/`);
    return res.data;
  }
);

export const clearCarouselPosts = createAsyncThunk(
  "CLEAR_CAROUSEL_POSTS",
  async () => null
);

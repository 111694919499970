import { createAsyncThunk } from "@reduxjs/toolkit";

import { CREATE_NB_MEMBER_FORM } from "../@types";

import axios, { AxiosResponse } from "axios";
import apiUrl from "../constants/apiUrl";

const baseApiUrl = `${apiUrl}/nb-members/`;

export const GET_NB_MEMBERS = "GET_NB_MEMBERS";
export const getNbMembers = createAsyncThunk(GET_NB_MEMBERS, async () => {
  const res: AxiosResponse = await axios.get(baseApiUrl);
  return res.data;
});

export const CREATE_NB_MEMBER = "CREATE_NB_MEMBER";
export const createNbMember = createAsyncThunk(
  CREATE_NB_MEMBER,
  async (nbMemberData: CREATE_NB_MEMBER_FORM, { rejectWithValue }) => {
    try {
      const res: AxiosResponse = await axios.post(baseApiUrl, nbMemberData);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const DELETE_NB_MEMBER = "DELETE_NB_MEMBER";
export const deleteNbMember = createAsyncThunk(
  DELETE_NB_MEMBER,
  async (nbMemberId: number | string) => {
    try {
      await axios.delete(`${baseApiUrl}${nbMemberId}/`);
      return nbMemberId;
    } catch (err) {
      return false;
    }
  }
);

export const EDIT_NB_MEMBER = "EDIT_NB_MEMBER";
export const editNbMember = createAsyncThunk(
  EDIT_NB_MEMBER,
  async (nbMemberToEdit: CREATE_NB_MEMBER_FORM & { id: number | string }) => {
    try {
      const { id, ...nbMemberDataToSend } = nbMemberToEdit;
      await axios.put(`${baseApiUrl}${id}/`, nbMemberDataToSend);
      return nbMemberToEdit;
    } catch (err) {
      return null;
    }
  }
);

export const GET_NB_MEMBER = "GET_NB_MEMBER";
export const getNbMember = createAsyncThunk(
  GET_NB_MEMBER,
  async (id: string | number, { rejectWithValue }) => {
    try {
      const res: AxiosResponse = await axios.get(`${baseApiUrl}${id}/`);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const clearNbMembers = createAsyncThunk(
  "CLEAR_NB_MEMBERS",
  async () => null
);

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editChapter, getChapter } from "../../actions/chapters";
import { RootState, AppDispatch } from "../../store";
import { CHAPTER, CREATE_CHAPTER_FORM } from "../../@types";

interface EditChapterFormProps {
  chapterId: string | number;
  onClose: () => void;
}

const EditChapterForm: React.FC<EditChapterFormProps> = ({
  chapterId,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { chapters } = useSelector((state: RootState) => state.chapters);

  const [formData, setFormData] = useState<CHAPTER>({
    id: chapterId,
    chapter_name: "",
    chapter_sign: "",
    chapter_founding_date: "",
    chapter_status: "",
    school_website: "",
    school_logo: "",
  });

  useEffect(() => {
    dispatch(getChapter(chapterId));
  }, [dispatch, chapterId]);

  useEffect(() => {
    const currentChapter = chapters.find(
      (chapter: CHAPTER) => chapter.id === chapterId
    );
    if (currentChapter) {
      setFormData({
        id: chapterId,
        chapter_name: currentChapter.chapter_name || "",
        chapter_sign: currentChapter.chapter_sign || "",
        chapter_founding_date: currentChapter.chapter_founding_date || "",
        chapter_status: currentChapter.chapter_status || "",
        school_website: currentChapter.school_website || "",
        school_logo: currentChapter.school_logo || "",
      });
    }
  }, [chapters, chapterId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.chapter_name && formData.chapter_sign) {
      dispatch(editChapter(formData));
      onClose();
    } else {
      alert("Please fill in all required fields.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4">Edit Chapter</h2>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div className="flex flex-col">
          <label className="mb-1 font-medium">Chapter Name</label>
          <input
            type="text"
            name="chapter_name"
            value={formData.chapter_name}
            onChange={handleChange}
            className="px-4 py-2 border rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium">Chapter Sign</label>
          <input
            type="text"
            name="chapter_sign"
            value={formData.chapter_sign}
            onChange={handleChange}
            className="px-4 py-2 border rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium">Founding Date</label>
          <input
            type="date"
            name="chapter_founding_date"
            value={formData.chapter_founding_date.toString()}
            onChange={handleChange}
            className="px-4 py-2 border rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium">Chapter Status</label>
          <input
            type="text"
            name="chapter_status"
            value={formData.chapter_status}
            onChange={handleChange}
            className="px-4 py-2 border rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium">School Website</label>
          <input
            type="text"
            name="school_website"
            value={formData.school_website}
            onChange={handleChange}
            className="px-4 py-2 border rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium">School Logo</label>
          <input
            type="text"
            name="school_logo"
            value={formData.school_logo}
            onChange={handleChange}
            className="px-4 py-2 border rounded-md"
          />
        </div>
      </div>
      <div className="flex justify-end mt-6">
        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded-md mr-2 hover:bg-blue-700"
        >
          Save Changes
        </button>
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default EditChapterForm;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { CHAPTER, CREATE_CHAPTER_FORM } from "../@types";
import axios, { AxiosResponse } from "axios";
import apiUrl from "../constants/apiUrl";

const baseApiUrl = `${apiUrl}/chapters/`;

export const GET_CHAPTERS = "GET_CHAPTERS";
export const getChapters = createAsyncThunk(GET_CHAPTERS, async () => {
  const res: AxiosResponse = await axios.get(baseApiUrl);
  return res.data;
});

export const CREATE_CHAPTER = "CREATE_CHAPTER";
export const createChapter = createAsyncThunk(
  CREATE_CHAPTER,
  async (chapterData: CREATE_CHAPTER_FORM, { rejectWithValue }) => {
    try {
      const res: AxiosResponse = await axios.post(baseApiUrl, chapterData);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const DELETE_CHAPTER = "DELETE_CHAPTER";
export const deleteChapter = createAsyncThunk(
  DELETE_CHAPTER,
  async (ChapterId: number | string) => {
    try {
      await axios.delete(`${baseApiUrl}${ChapterId}/`);
      return ChapterId;
    } catch (err) {
      return false;
    }
  }
);

export const EDIT_CHAPTER = "EDIT_CHAPTER";
export const editChapter = createAsyncThunk(
  EDIT_CHAPTER,
  async (chapterToEdit: CHAPTER) => {
    try {
      const { id } = chapterToEdit;
      const chapterData: any = { ...chapterToEdit };
      delete chapterData.id;
      const response: AxiosResponse = await axios.put(
        `${baseApiUrl}${id}/`,
        ...chapterData
      );
      return response.data;
    } catch (err) {
      return null;
    }
  }
);

export const GET_CHAPTER = "GET_CHAPTER";
export const getChapter = createAsyncThunk(
  GET_CHAPTER,
  async (id: string | number) => {
    const res: AxiosResponse = await axios.get(`${baseApiUrl}${id}/`);
    return res.data;
  }
);

export const clearChapters = createAsyncThunk(
  "CLEAR_CHAPTERS",
  async () => null
);

import { FunctionComponent } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Admin from "./pages/Admin";
import CreateChapter from "./pages/CreateSdaChapter";
import ManageMembers from "./pages/ManageMembers";
import ManageChapters from "./pages/ManageChapters";
import ManageEvents from "./pages/ManageEvents";
import ManageNews from "./pages/ManageNews";
import CreateMember from "./pages/CreateSdaMember";
import CreateSdaEventPost from "./pages/CreateSdaEventPost";
import CreateSdaNewsPost from "./pages/CreateSdaNewsPost";
import ChaptersPage from "./pages/Chapters";
import ManageNbMembers from "./pages/ManageNbMembers";
import CreateNbMember from "./pages/CreateSdaNbMember";
import NewsAndEventsPage from "./pages/NewsAndEventPage";
import EventsPostPage from "./pages/EventPostPage";
import NewsPostPage from "./pages/NewsPostPage";
import NationalBoardPage from "./pages/NationalBoardPage";
import ContactPage from "./pages/ContactPage";
import AlumniPage from "./pages/AlumniPage";
import OurStoryPage from "./pages/OurStoryPage";
import ManageCarouselPosts from "./pages/ManageCarouselPosts";
import CreateCarouselPost from "./pages/CreateSdaCarouselPost";
import ScholarshipPage from "./pages/ScholarshipPage";
import Error404 from "./pages/PageNotFound";

const SdaRoutes: FunctionComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<Admin />}>
          <Route path="manage-members" element={<ManageMembers />} />
          <Route path="manage-chapters" element={<ManageChapters />} />
          <Route path="manage-events" element={<ManageEvents />} />
          <Route path="manage-news" element={<ManageNews />} />
          <Route path="manage-nb-members" element={<ManageNbMembers />} />
          <Route
            path="manage-carousel-posts"
            element={<ManageCarouselPosts />}
          />
          <Route path="" element={<Navigate to="manage-members" />} />
        </Route>
        <Route path="/create-chapter" element={<CreateChapter />} />
        <Route path="/create-member" element={<CreateMember />} />
        <Route path="/create-event-post" element={<CreateSdaEventPost />} />
        <Route path="/create-news-post" element={<CreateSdaNewsPost />} />
        <Route path="/create-nb-member" element={<CreateNbMember />} />
        <Route path="/create-carousel-post" element={<CreateCarouselPost />} />

        <Route path="/event-post/:id" element={<EventsPostPage />} />
        <Route path="/news-post/:id" element={<NewsPostPage />} />

        <Route path="chapters" element={<ChaptersPage />} />
        <Route path="news-and-events" element={<NewsAndEventsPage />} />
        <Route path="national-board" element={<NationalBoardPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="scholarship" element={<ScholarshipPage />} />
        <Route path="alumni" element={<AlumniPage />} />
        <Route path="our-story" element={<OurStoryPage />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default SdaRoutes;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import { editEventPost, getEventPost } from "../../actions/events";
import { CREATE_EVENT_POST_FORM, EVENT_POST } from "../../@types";

interface EditEventPostFormProps {
  eventPostId: number | string;
  onClose: () => void;
}

const EditEventPostForm: React.FC<EditEventPostFormProps> = ({
  eventPostId,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { eventPosts } = useSelector((state: RootState) => state.events);
  const [formData, setFormData] = useState<EVENT_POST>({
    id: eventPostId,
    event_title: "",
    event_start_date: "",
    event_end_date: "",
    event_body: "",
    event_image: "",
    event_to_display: false,
  });

  useEffect(() => {
    dispatch(getEventPost(eventPostId));
  }, [dispatch, eventPostId]);

  useEffect(() => {
    const currentEvents = eventPosts.find(
      (events: EVENT_POST) => events.id === eventPostId
    );
    if (currentEvents) {
      setFormData({
        id: eventPostId,
        event_title: currentEvents.event_title || "",
        event_start_date: currentEvents.event_start_date || "",
        event_end_date: currentEvents.event_end_date || "",
        event_body: currentEvents.event_body || "",
        event_image: currentEvents.event_image || "",
        event_to_display: currentEvents.event_to_display || false,
      });
    }
  }, [eventPosts, eventPostId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.event_title) {
      dispatch(editEventPost(formData));
      onClose();
    } else {
      alert("Please fill in all required fields.");
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <form onSubmit={handleSubmit} className="space-y-6">
        <h2 className="text-2xl font-bold mb-4">Edit Event Post</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Event Name
            </label>
            <input
              type="text"
              name="event_title"
              value={formData.event_title}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Start Date
            </label>
            <input
              type="date"
              name="event_start_date"
              value={formData.event_start_date.toString()}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              End Date
            </label>
            <input
              type="date"
              name="event_end_date"
              value={formData.event_end_date.toString()}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Body Post
            </label>
            <input
              type="text"
              name="event_body"
              value={formData.event_body}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Image
            </label>
            <input
              type="text"
              name="event_image"
              value={formData.event_image}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="event_to_display"
              checked={Boolean(formData.event_to_display)}
              onChange={handleChange}
              className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <label className="ml-2 block text-sm font-medium text-gray-700">
              To Display
            </label>
          </div>
        </div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditEventPostForm;
